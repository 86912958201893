'use strict';

import Bowser from "bowser";

document.addEventListener("DOMContentLoaded", function() {
    let browser = Bowser.getParser(window.navigator.userAgent);
    console.log(`The current browser name is "${browser.getBrowserName()}" and its version is "${browser.getBrowserVersion()}".`);
    let isValidBrowser = browser.satisfies({
        chrome: ">70",
        chromium: ">74",
        firefox: ">31",
        opera: ">22",
        edge: ">40",
        ie: ">10",
        safari: ">12"
    });
    if(!isValidBrowser){
        window.location.href = "/invalid-browser";
    }
});